<template>
  <div>
    <h1>Not Found</h1>
    <router-link :to="{name: 'Home'}">Look at the remaining topics</router-link>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
