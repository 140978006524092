<template>
  <!-- Header -->
  <app-header-bank class="container" />

  <!--  Первый экран-->
  <bank-first-screen class="bank--first-screen">
    <template #title>Гарантии</template>
    <template #subtitle>Для госзакупок</template>
  </bank-first-screen>

  <section class="container bank--second-screen">
    <app-title>Для госзакупок</app-title>
    <h2 class="whom-title">В рамках 44-ФЗ,<br>223-ФЗ и 615-ПП</h2>
    <div class="whom-list">
      <selection-point
        v-for="text of whomList"
        :key="text"
        :text="text"
        class="whom-item"
      />
    </div>
    <img class="whom-img" src="@/assets/image/bank/gas-bank.png" alt="север газ банк" title="север газ банк">
  </section>

  <!-- Для кого? -->
  <section class="container whom">
    <app-title>Для кого?</app-title>
    <div class="whom-list">
      <selection-point
        text="Участники госзакупок"
        class="whom-item"
      />
      <selection-point
        text="Преимущественно<br>малый и средний бизнес"
        class="whom-item"
      />
    </div>
  </section>

  <!-- Преимущества -->
  <section class="container">
    <app-title inversion>Преимущества</app-title>
    <check-list-wrapper class="benefist-wrapper">
      <check-list
        v-for="(_, idx) of benefits"
        :key="idx"
        :listLength="benefits.length"
        :text="benefits[idx]"
        :idx="idx"
        :exceptionIdx="exceptionIdx"
        @update:modelValue="v => exceptionIdx = v"
        class="benefist-item"
      />
    </check-list-wrapper>
  </section>

  <!-- Тарифы и документы -->
  <section class="container bank--mb">
    <app-title inversion>Тарифы и документы</app-title>

    <insurance-info class="incorais">
      <template #image>
        <img
          class="incorais-image"
          src="@/assets/image/main-content-1.png"
          alt="Что застраховано"
          title="Что застраховано"
        >
      </template>
      <insurance-info-list></insurance-info-list>
    </insurance-info>
  </section>

  <section class="container bank--mb">
    <!-- Отправить заявку -->
    <app-button class="bank--btn" @click="openModal">Отправить заявку</app-button>
  </section>

  <app-footer />

  <app-modal
    id="app-modal"
    ref="modalForm"
    @send="send"
  >
    <template #title>
      Заполните форму
    </template>
    <template #form>
      <!--   FormInputsList   -->
      <div class="form-field">
        <form-custom-input
          :format="$options.formTypes.input"
          :modelValue="principal"
          :error="v$.principal.$error"
          placeholder="Наименование организации"
          @update:modelValue="v => principal = v"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          required
          :format="$options.formTypes.input"
          :error="v$.innPrincipal.$error"
          :modelValue="innPrincipal"
          placeholder="ИНН организации"
          @update:modelValue="v => innPrincipal = v"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          :format="$options.formTypes.input"
          :error="v$.beneficiary.$error"
          :modelValue="beneficiary"
          placeholder="Наименование заказчика"
          @update:modelValue="v => beneficiary = v"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          :format="$options.formTypes.input"
          :error="v$.innBeneficiary.$error"
          :modelValue="innBeneficiary"
          placeholder="ИНН заказчика"
          @update:modelValue="v => innBeneficiary = v"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          :format="$options.formTypes.input"
          :error="v$.linkToPurchase.$error"
          :modelValue="linkToPurchase"
          placeholder="Ссылка на закупку / № лота"
          @update:modelValue="v => linkToPurchase = v"
        />
      </div>
      <div class="form-field">
        <MultiSelect
          class="multi-select"
          :class="{'error': v$.typesOfContracts.picked.$error}"
          v-model="typesOfContracts.picked"
          :options="typesOfContracts.values"
          optionLabel="label"
          optionValue="value"
          appendTo="self"
          placeholder="Вид контрактов*"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          :format="$options.formTypes.input"
          :error="v$.termGuarantee.$error"
          :modelValue="termGuarantee"
          v-imask="maskNumber"
          placeholder="Сумма гарантий, ₽"
          @update:modelValue="v => termGuarantee = v"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          :format="$options.formTypes.input"
          :error="v$.warrantyPeriod.$error"
          :modelValue="warrantyPeriod"
          v-imask="maskDays"
          placeholder="Срок гарантий, дн."
          @update:modelValue="v => warrantyPeriod = v"
          @accept="onAccept"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          required
          :format="$options.formTypes.input"
          :error="v$.name.$error"
          :modelValue="name"
          @update:modelValue="v => name = v"
          placeholder="Имя"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          required
          :format="$options.formTypes.input"
          :error="v$.tel.$error"
          :modelValue="tel"
          v-imask="maskTelephone"
          placeholder="Телефон"
          @update:modelValue="v => tel = v"
          @accept="onAccept"
        />
      </div>
      <div class="form-field">
        <form-custom-input
          :format="$options.formTypes.input"
          :modelValue="email"
          :error="v$.email.$error"
          placeholder="Почта"
          @update:modelValue="v => email = v"
        />
      </div>
  </template>
  </app-modal>
</template>

<script>
import AppHeaderBank from '@/components/AppHeaderBank'
import AppFooter from '@/components/AppFooter'
import AppTitle from '@/components/AppTitle'
import AppModal from '@/components/AppModal'
import BankFirstScreen from '@/components/BankFirstScreen'
import CheckListWrapper from '@/components/CheckListWrapper'
import CheckList from '@/components/CheckList'
import InsuranceInfo from '@/components/InsuranceInfo'
import InsuranceInfoList from '@/components/InsuranceInfoList'
import SelectionPoint from '@/components/SelectionPoint'
import FormCustomInput from '@/components/FormCustomInput'
import { email, required, numeric } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core/dist/index.esm'
import { FORM_TYPE_CHOICE, FORM_TYPE_INPUT } from '@/consts'
import sendForm from '@/sendForm'
import {IMask, IMaskDirective} from "vue-imask";

export default {
  name: 'AppBank',
  components: {
    AppHeaderBank,
    AppFooter,
    AppTitle,
    AppModal,
    BankFirstScreen,
    CheckListWrapper,
    CheckList,
    InsuranceInfo,
    InsuranceInfoList,
    SelectionPoint,
    FormCustomInput
  },
  directives: {
    imask: IMaskDirective
  },
  formTypes: {
    input: FORM_TYPE_INPUT,
    choice: FORM_TYPE_CHOICE
  },
  setup () { return { v$: useVuelidate() } },
  data() {
    return {
      benefits: [
        'Быстрое<br>решение',
        'Он-лайн<br>заявка',
        'Всё в одном<br>месте',
        'Свободный лимит<br>на гарантию'
      ],
      whomList: [
        'Для участия <br>в конкурсах',
        'На гарантийный период',
        'Для заключения <br>контрактов',
        'Гарантию выпускает банк-партнёр<br>СЕВЕРГАЗБАНК на основании<br>заключения ВСК'
      ],
      exceptionIdx: 0,

      principal: '',
      innPrincipal: '',
      beneficiary: '',
      innBeneficiary: '',
      linkToPurchase: '',
      typesOfContracts: {
        values: [
          {
            value: 'Исполнение контракта',
            label: 'Исполнение контракта',
          },
          {
            value: 'Участие в торгах',
            label: 'Участие в торгах',
          },
          {
            value: 'Гарантийные обязательства',
            label: 'Гарантийные обязательства',
          },
          {
            value: 'Возврат аванса',
            label: 'Возврат аванса',
          },
        ],
        picked: [],
      },
      termGuarantee: '',
      warrantyPeriod: '',
      name: '',
      tel: '',
      email: '',

      maskDays: {
        mask: Number,  // enable number mask
        min: 0,
        max: 10000
      },
      maskNumber: {
        mask: Number,  // enable number mask
        signed: false,  // disallow negative
        thousandsSeparator: ' ',  // any single char
        padFractionalZeros: false,  // if true, then pads zeros at end to the length of scale
        normalizeZeros: true,  // appends or removes zeros at ends
        min: 0,
        max: 100000000000
      },

      maskTelephone: {
        mask: '+{7}(000)000-00-00',
        lazy: true,
        placeholderChar: '_'
      },
      maskDate: {
        mask: Date,
        lazy: true,
        autofix: true,
        blocks: {
          d: {mask: IMask.MaskedRange, placeholderChar: 'd', from: 1, to: 31, maxLength: 2},
          m: {mask: IMask.MaskedRange, placeholderChar: 'm', from: 1, to: 12, maxLength: 2},
          Y: {mask: IMask.MaskedRange, placeholderChar: 'y', from: 1900, to: 2999, maxLength: 4}
        }
      }
    }
  },
  methods: {
    async openModal() {
      await this.$refs.modalForm.open();
    },
    async send() {
      const result = await this.v$.$validate()
      console.log('result', result);
      if (!result) return
      const str = `
      Принципал: ${this.principal};
      ИНН Принципала: ${this.innPrincipal};
      Бенефициар: ${this.beneficiary};
      ИНН Бенефициара ${this.innBeneficiary};
      Ссылка на закупку: ${this.linkToPurchase};
      Вид контракта: ${this.typesOfContracts.picked};
      Сумма гарантии: ${this.termGuarantee};
      Срок гарантии: ${this.warrantyPeriod};
      name ${this.name};
      tel: ${this.tel};
      email: ${this.email}`

      try {
        await sendForm(str)
        this.$refs.modalForm.assecc()
        this.clearModal()
      } catch (e) {
        alert('Не получилось отправить письмо на почту. Попробуйте снова!')
      }
    },
    clearModal() {
      this.v$.$reset()
      this.principal = ''
      this.innPrincipal = ''
      this.beneficiary = ''
      this.innBeneficiary = ''
      this.linkToPurchase = ''
      this.typesOfContracts.picked = []
      this.termGuarantee = ''
      this.warrantyPeriod = ''
      this.name = ''
      this.tel = ''
      this.email = ''
    },
    onAccept (e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
  },
  validations () {
    const mustBeLength = (value) => (value.length > 0);
    return {
      principal: { },
      innPrincipal: { required, numeric },
      beneficiary: { },
      innBeneficiary: { numeric },
      typesOfContracts: {
        picked: { mustBeLength }
      },
      linkToPurchase: {},
      termGuarantee: {},
      warrantyPeriod: {},
      name: { required },
      tel: { required },
      email: { email }
    }
  }
}
</script>

<style scoped lang="scss">
@use '@/assets/styles/mixins';
@use '@/assets/styles/colors';

.bank {
  &--mb {
    margin-bottom: 60px;

    @media (min-width: 560px) {
      margin-top: 70px;
    }

    @media (min-width: 968px) {
      margin-top: 100px;
    }

    @media (min-width: 1220px) {
      margin-bottom: 127px;
    }
  }

  &--btn {
    margin: 0 auto;
    display: block;
  }

  &--first-screen {
    :deep(.insurance-products--fon) {
      height: 100%;
    }
  }

  &--second-screen {
    margin-top: 50px;

    @media (min-width: 560px) {
      margin-top: 70px;
    }

    @media (min-width: 968px) {
      margin-top: 100px;
    }

    @media (min-width: 1220px) {
      margin-top: 140px;
    }
  }
}

.whom {
  margin: 35px auto 50px;

  @media (min-width: 560px) {
    margin-bottom: 80px;
  }

  @media (min-width: 968px) {
    margin-bottom: 110px;
  }

  @media (min-width: 1220px) {
    margin-bottom: 150px;
  }

  &-title {
    @include mixins.setFontParams(500, 20px);
    color: colors.$textDark;
    margin-bottom: 62px;

    @media (min-width: 560px) {
      font-size: 25px;
    }

    @media (min-width: 968px) {
      font-size: 30px;
    }

    @media (min-width: 1220px) {
      font-size: 35px;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-item {
    flex: 1 0 100%;

    @media (min-width: 560px) {
      flex: 1 0 50%;
    }

    :deep(.paragraph-icon) {
      height: 34px;

      @media (min-width: 560px) {
        height: 40px;
      }

      @media (min-width: 968px) {
        height: 46px;
      }

      @media (min-width: 1220px) {
        height: 52px;
      }
    }

    :deep(.paragraph-text) {
      font-size: 16px;

      @media (min-width: 560px) {
        font-size: 19px;
      }

      @media (min-width: 968px) {
        font-size: 25px;
      }

      @media (min-width: 1220px) {
        font-size: 30px;
      }
    }
  }

  &-img {
    display: block;
    width: 100%;

    @media (min-width: 560px) {
      margin-left: auto;
      width: 50%;
    }
  }
}

.benefist {
  &-wrapper {
    margin-bottom: 50px;

    @media (min-width: 560px) {
      margin-bottom: 70px;
    }

    @media (min-width: 968px) {
      margin-bottom: 110px;
    }

    @media (min-width: 1220px) {
      margin-bottom: 140px;
    }
  }

  &-item {
    padding: 10px 10px 10px 20px;
    font-size: 14px;
    flex-basis: calc(50% - 30px);
    align-items: center;

    @media (min-width: 560px) {
      font-size: 20px;
    }

    @media (min-width: 968px) {
      padding: 20px 15px 20px 25px;
      flex-basis: calc(50% - 40px);
      font-size: 25px;
    }

    @media (min-width: 1220px) {
      padding: 40px 20px 40px 30px;
      flex-basis: calc(50% - 50px);
      font-size: 35px;
    }

    :deep(.item-num) {
      font-size: 70px;

      @media (min-width: 560px) {
        font-size: 80px;
      }

      @media (min-width: 968px) {
        font-size: 110px;
      }

      @media (min-width: 1220px) {
        font-size: 130px;
      }
    }
  }
}

.incorais {
  :deep(.insurance-content) {
    padding: 26px 22px 33px 40px;
    width: calc(100% - 62px);

    @media (min-width: 560px) {
      padding: 38px 50px 47px 55px;
      width: calc(100% - 105px);
    }

    @media (min-width: 920px) {
      padding: 55px 70px 60px 58px;
      flex-basis: calc(50% - 138px);
      width: auto;
    }

    @media (min-width: 1220px) {
      padding: 66px 82px 76px 60px;
      flex-basis: calc(50% - 142px);
    }
  }

  &-image {
    width: 100%;
  }
}

.form-field {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
</style>
<style lang="scss">
@use '@/assets/styles/mixins';
@use '@/assets/styles/colors';
.multi-select {
  position: relative;
  border: 2px solid #94C7E9;
  border-radius: 30px;
  background-color: colors.$textWhite;
  padding: 21px 27px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;

  &.error {
    border: 2px solid red;
  }

  &.p-overlay-open .p-multiselect-trigger {
    transform: rotate(180deg);
  }
  .p-multiselect-trigger {
    transition: transform ease .2s;
  }

  .p-multiselect-panel {
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px #d3d3d3;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 10px;
  }

  .p-multiselect-header {
    position: relative;
  }
  .p-multiselect-header .p-checkbox::after {
    position: absolute;
    left: 30px;
    white-space: nowrap;
    content: 'Выбрать все',
  }

  .p-checkbox {
    width: 20px;
    height: 20px;
  }
  .p-checkbox .p-checkbox-box {
    border: 2px solid #ced4da;
    background: #ffffff;
    width: 20px;
    height: 20px;
    color: #495057;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.2s;
    color: #ffffff;
    font-size: 14px;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #2196F3;
    background: #2196F3;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #2196F3;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
    border-color: #2196F3;
  }
  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #0b7ad1;
    background: #0b7ad1;
    color: #ffffff;
  }
  .p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #f44336;
  }

  .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.5rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    color: #6c757d;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
  }
  .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: 0.5rem;
  }
  .p-multiselect .p-multiselect-trigger {
    background: transparent;
    color: #6c757d;
    width: 2.357rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .p-multiselect.p-invalid.p-component {
    border-color: #f44336;
  }

  .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.25rem 0.5rem;
  }

  .p-multiselect-panel {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  .p-multiselect-panel .p-multiselect-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: 0.5rem;
    color: #6c757d;
  }
  .p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0.5rem;
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  }
  .p-multiselect-panel .p-multiselect-items {
    padding: 0.5rem 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: 0.5rem 1rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #495057;
    background: #E3F2FD;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight.p-focus {
    background: rgba(33, 150, 243, 0.24);
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: #495057;
    background: #dee2e6;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: 0.75rem 1rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
  }
  .p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: 0.5rem 1rem;
    color: #495057;
    background: transparent;
  }
}
</style>
