import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'

import AppButton from './components/AppButton'
import '@/assets/styles/main.scss'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
// import 'primevue/resources/themes/saga-blue/theme.css'

import PrimeVue from 'primevue/config';

import MultiSelect from 'primevue/multiselect';

const app = createApp(App)

app.use(PrimeVue);

app.component('AppButton', AppButton)
app.component('MultiSelect', MultiSelect)

app.use(router)
app.mount('#app')
